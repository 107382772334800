<template>
    <SuccessScreen
        titleKey="ask-visit.success.title"
        messageKey="ask-visit.success.message"
        nextRouteName="detalle-alojamiento"
        :nextRouteParams="{ idalojamiento: accommodationId }"
        buttonTextKey="ask-visit.success.button-text"
        :backNavigationSteps=3
    />
</template>

<script>
import SuccessScreen from '@/components/SuccessScreen.vue';

export default {
    name: 'AccommodationVisitSuccess',
    components: {
        SuccessScreen
    },
    computed: {
        accommodationId() {
            return this.$route.params.accommodationId;
        }
    }
}
</script>